<template>
	<button
		class="text-gray-400"
		type="button"
		tabindex="-1"
		@click.exact="toggle"
	>
		<IconSlashedEye v-if="modelValue" />
		<IconEye v-else />
	</button>
</template>

<script setup lang="ts">

const props = defineProps({
	modelValue: {
		type: Boolean,
		default: false
	}
})

const { modelValue } = toRefs(props)
const emit = defineEmits([ 'update:modelValue' ])

const toggle = () => {
	emit('update:modelValue', !modelValue.value)
}
</script>
