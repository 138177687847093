export const TEXT = 'text'
export const TEXTAREA = 'textarea'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const NUMBER = 'number'
export const DATE = 'date'
export const SEARCH = 'mx-search'
export const TEL = 'tel'
export const ZIP = 'zip'

export default {
	TEXT, TEXTAREA, EMAIL, PASSWORD, NUMBER, DATE, SEARCH, TEL, ZIP
}
