import INPUT_STATES from '@/constants/form/states.js'
import INPUT_TYPES from '@/constants/form/text-inputs/types.js'
// import { logError } from '@/utils/error-handling.js'

export const inputStateValidator = (value) => {
	if (typeof value === 'boolean') {
		return true
	}
	const isValid = Object.keys(INPUT_STATES)
		.map(type => type.toLowerCase())
		.includes(value.toLowerCase())

	if (typeof value !== 'string' || !isValid) {
		// logError(`Invalid input state provided: ${value}`)
	}
	return isValid
}

export const inputTypeValidator = (value) => {
	const isValid = Object.values(INPUT_TYPES)
		.map(type => type.toLowerCase())
		.includes(value.toLowerCase())
	if (typeof value !== 'string' || !isValid) {
		// logError(`Invalid input type provided: ${value}`)
	}
	return isValid
}

export const inputFieldStateValidator = (value, type) => {
	if (type === INPUT_TYPES.EMAIL) {
		return value?.length && value.includes('@') ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
	} else if (type === INPUT_TYPES.ZIP) {
		const usPostalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
		const canadaPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
		return usPostalCodeRegex.test(value) || canadaPostalCodeRegex.test(value) ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
	}
	return value?.length ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
}

export const inputNameValidator = (value) => {
	const nameRegex = /^[a-zA-Z0-9_.+\- ]+$/
	return nameRegex.test(value.trim()) ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
}

export const inputPasswordValidator = (value, email, phone) => {
	if (!value) { return INPUT_STATES.NONE }
	/*
		Regex for password validation:
		At least one uppercase letter.
		At least one lowercase letter.
		At least one number.
		At least one special character.
		A minimum length of 12 characters.
	*/
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/
	return passwordRegex.test(value) &&
			value !== email &&
			value !== phone
		? INPUT_STATES.SUCCESS
		: INPUT_STATES.ERROR
}

export const inputPasswordConfirmValidator = (value, password) => {
	return value === password ? INPUT_STATES.SUCCESS : INPUT_STATES.ERROR
}

export default { inputStateValidator, inputTypeValidator, inputNameValidator, inputFieldStateValidator, inputPasswordValidator, inputPasswordConfirmValidator }
