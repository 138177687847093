<template>
	<svg
		width="30"
		height="25"
		viewBox="0 0 40 30"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M39.5377 13.7523C38.3953 12.4374 36.3686 10.2806 33.7569 8.20037L29.0563 12.9337C29.206 13.5969 29.2843 14.2904 29.2843 15C29.2843 20.1554 25.118 24.3506 19.9983 24.3506C19.2935 24.3506 18.6072 24.2718 17.9462 24.1211L15.2816 26.8043C16.8062 27.2635 18.3861 27.5301 19.996 27.5301C28.8606 27.5301 36.7763 19.4249 39.5354 16.2523C40.1549 15.5334 40.1549 14.4666 39.5354 13.7546L39.5377 13.7523Z" />
		<path d="M31.0416 6.23377L34.8992 2.34926L32.5662 0L28.066 4.53154C26.9904 4.00278 25.8711 3.54824 24.7173 3.20269C23.1927 2.74351 21.6127 2.47681 20.0006 2.47681C11.1429 2.47449 3.2249 10.5798 0.468102 13.7523C-0.156034 14.4643 -0.156034 15.5334 0.468102 16.25C1.61043 17.5626 3.63715 19.7217 6.24655 21.7996C7.09178 22.4745 8.0015 23.1401 8.96189 23.7662L5.10422 27.6507L7.43724 30L29.7288 7.55334L31.0416 6.23145V6.23377ZM21.9029 10.7352C21.3226 10.4708 20.6777 10.3247 20.0006 10.3247C17.4418 10.3247 15.3576 12.4212 15.3576 15C15.3576 15.6818 15.5027 16.3312 15.7652 16.9156L12.3774 20.327C11.7072 19.3576 11.2143 18.2537 10.9494 17.0663C10.7997 16.4007 10.7214 15.7096 10.7214 14.9977C10.7214 9.8423 14.8831 5.64703 20.0006 5.64703C20.7076 5.64703 21.3962 5.72588 22.0572 5.87894C23.2364 6.14796 24.3327 6.64425 25.2954 7.31911L21.9052 10.7328L21.9029 10.7352Z" />
	</svg>
</template>
