<template>
	<div :class="`absolute w-5 h-5 text-white transform -translate-y-1/2 rounded-full pointer-events-none ${xPosition} top-1/2`">
		<slot />
	</div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'
import type { Position } from '@/components/form/types'
const props = defineProps({
	position: {
		type: String as PropType<Position>,
		required: true
	}
})

const { position } = toRefs(props)

const xPosition = computed(() => {
	return position.value === 'left' ? 'left-2' : 'right-2'
})
</script>
